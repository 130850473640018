import { Endpoint, Resource, RequestMethod } from 'common/resources/rabbi-resources';
import UrlHelper from 'utils/url-helper';
import { PARAMS } from 'common/constants';
import { apiResponse, apiRequest } from './interceptors';

const { POST, GET, PUT, PATCH, DELETE, HEAD } = RequestMethod;
const {
	STORY_ID,
	CARD_ID,
	ORGANIZATION_ID,
	DOMAIN_ID,
	TEMPLATE_ID,
	TEAM_ID,
	MEMBER_ID,
	WEBHOOK_ID,
	WEBHOOK_LOG_ID,
	GENERATOR_ID,
	COLLECTION_ID,
} = PARAMS;

const API = {
	v1: new Resource(UrlHelper.adminBackend, {
		headers: { Accept: 'application/json' },
		requestInterceptors: [apiRequest],
		responseInterceptors: [apiResponse],
	}),
	v2: new Resource(UrlHelper.adminBackendV2, {
		headers: { Accept: 'application/json' },
		requestInterceptors: [apiRequest],
		responseInterceptors: [apiResponse],
	}),
	v2r: new Resource(UrlHelper.adminBackendV2R, {
		headers: { Accept: 'application/json' },
		requestInterceptors: [apiRequest],
		responseInterceptors: [apiResponse],
	}),
};

type ApiType = keyof typeof API;

// auth:
const me = new Endpoint(API.v1, '/auth/me', { type: GET });
const logout = new Endpoint(API.v1, '/logout');

// auth v2:
const auth = {
	get: {
		totp: new Endpoint(API.v2r, '/auth/totp', { type: GET }),
	},
	post: {
		login: new Endpoint(API.v2r, '/auth/login', { type: POST }),
		totp: new Endpoint(API.v2r, '/auth/totp', { type: POST }),
		totpRequirement: new Endpoint(API.v2r, '/auth/totp/requirement', { type: POST }),
		totpVerify: new Endpoint(API.v2r, '/auth/totp/verify', { type: POST }),
		totpRecoveryCodes: new Endpoint(API.v2r, '/auth/totp/recovery-codes', { type: POST }),
		totpRemove: new Endpoint(API.v2r, '/auth/totp/remove', { type: POST }),
	},
};

const cms = {
	get: {
		collections: new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/collections`, { type: GET }),
		collectionItems: new Endpoint(API.v2r, `/collection/:${COLLECTION_ID}/items`, { type: GET }),
	},
};

// story:
const getStory = new Endpoint(API.v1, `/story/:${STORY_ID}`, { type: GET, storyAdapter: true });
const createStory = new Endpoint(API.v1, '/story');
const duplicateStory = new Endpoint(API.v1, `/story/:${STORY_ID}/duplicate`, { type: POST });
const deleteStory = new Endpoint(API.v1, `/story/:${STORY_ID}`, { type: DELETE });
const updateStory = new Endpoint(API.v1, `/story/:${STORY_ID}`, { type: PUT });
const updateLatestStory = new Endpoint(API.v1, `/story/:${STORY_ID}/latest`, { type: PUT });
const updateStoryUrl = new Endpoint(API.v1, `/story/:${STORY_ID}/client-story-id`, { type: PATCH });
const updatePublishedStory = new Endpoint(API.v1, `/story/:${STORY_ID}/published`, { type: PUT });
const unpublishPublishedStory = new Endpoint(API.v1, `/story/:${STORY_ID}/unpublish`, { type: POST });
const postGallery = new Endpoint(API.v1, `/story/:${STORY_ID}/gallery`, { type: POST });
const getStoryAudience = new Endpoint(API.v1, `/story/:${STORY_ID}/audience`, { type: POST });
const getReportStoryBigPicture = (api: ApiType) =>
	new Endpoint(API[api], `/story/:${STORY_ID}/analytics/big-picture`, { type: GET });
const getReportStoryByCard = (api: ApiType) =>
	new Endpoint(API[api], `/story/:${STORY_ID}/analytics/card-by-card`, { type: GET });
const getCsvUrlStoryByCard = (api: ApiType) =>
	new Endpoint(API[api], `/story/:${STORY_ID}/analytics/card-by-card`, { type: POST });
const getStoryFlowStats = new Endpoint(API.v2r, `/story/:${STORY_ID}/analytics/flow`, { type: GET });
const storyRollback = new Endpoint(API.v1, `/story/:${STORY_ID}/rollback`, { type: POST });

const setDefaultStoryDomain = new Endpoint(API.v1, `/story/:${STORY_ID}/domain`, { type: PATCH });

// member:
const updateMemberEmail = new Endpoint(API.v1, `/member/:${MEMBER_ID}/email`, { type: PATCH });
const getMember = new Endpoint(API.v1, `/member/:${MEMBER_ID}`, { type: GET });
const resetPassword = new Endpoint(API.v1, `/member/password/reset`, { type: POST });
const restorePassword = new Endpoint(API.v1, `/member/password/restore`, { type: POST });
const getMemberPreferences = new Endpoint(API.v1, `/member/:${MEMBER_ID}/preferences`, { type: GET });
const setMemberPreferences = new Endpoint(API.v1, `/member/:${MEMBER_ID}/preferences`, { type: POST });
const updateMemberAvatar = new Endpoint(API.v1, `/member/:${MEMBER_ID}/profile-picture`, { type: PATCH });
const updateMemberPassword = new Endpoint(API.v1, `/member/:${MEMBER_ID}/password`, { type: PATCH });
const updateMember = new Endpoint(API.v1, `/member/:${MEMBER_ID}`, { type: PUT });
const verifyMember = new Endpoint(API.v1, `/member/verify`, { type: POST });
const verifyMemberResend = new Endpoint(API.v1, `/member/:${MEMBER_ID}/email/verify`, { type: POST });
const createMember = new Endpoint(API.v1, `/member`, { type: POST });
const deleteMember = new Endpoint(API.v1, `/member/:${MEMBER_ID}`, { type: DELETE });

// team:
const createTeam = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/team`, { type: POST });
const updateTeam = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/team/:${TEAM_ID}`, { type: PUT });
const deleteTeam = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/team/:${TEAM_ID}`, { type: DELETE });
const getTeamMembers = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/team/:${TEAM_ID}/member`, {
	type: GET,
});
const updateTeamMember = new Endpoint(
	API.v1,
	`/organization/:${ORGANIZATION_ID}/team/:${TEAM_ID}/member/:${MEMBER_ID}`,
	{
		type: PUT,
	}
);
const deleteTeamMember = new Endpoint(
	API.v1,
	`/organization/:${ORGANIZATION_ID}/team/:${TEAM_ID}/member/:${MEMBER_ID}`,
	{
		type: DELETE,
	}
);

// payment plans
const getPlans = new Endpoint(API.v1, `/plan`, { type: GET });
const getPlanChargeInfo = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/plan-switch`, { type: GET });

// organization:
const createOrganization = new Endpoint(API.v1, '/organization', { type: POST });
const validateOrganizationName = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}`, { type: HEAD });
const updateOrganizationSettings = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}`, { type: PUT });
const getPubNubAuthKey = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/pub-sub-key`, { type: GET });
const addMembersToOrganizationTeams = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/invite`, {
	type: POST,
});
const getOrganizationMembers = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/member`, { type: GET });
const getOrganizationGoogleFonts = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/fonts?sort=alpha`, {
	type: GET,
});
const getOrganizationAdminInvoices = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/invoice`, {
	type: GET,
});
const getOrganizationAdminBilling = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/billing`, {
	type: GET,
});
const getOrganizationDomains = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/domain`, { type: GET });
const removeMemberFromOrganization = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/member/:${MEMBER_ID}`, {
	type: DELETE,
});
const removeOrganizationDomain = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/domain/:${DOMAIN_ID}`, {
	type: DELETE,
});
const retryOrganizationDomainConnection = new Endpoint(
	API.v1,
	`/organization/:${ORGANIZATION_ID}/domain/:${DOMAIN_ID}/retry`,
	{ type: POST }
);
const updateOrganizationMember = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/member/:${MEMBER_ID}`, {
	type: PUT,
});
const updateOrganizationBilling = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/billing`, { type: PUT });
const updateOrganizationPayment = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/payment`, { type: PUT });
const resendInviteToOrganization = new Endpoint(
	API.v1,
	`/organization/:${ORGANIZATION_ID}/member/:${MEMBER_ID}/invite-resend`,
	{ type: POST }
);
const updateOrganizationDomain = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/domain`, { type: POST });
const setDefaultOrganizationDomain = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/domain`, {
	type: PATCH,
});
const getOrganizationDomainStatus = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/domain/:${DOMAIN_ID}`, {
	type: GET,
});
const getOrganizationStories = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/story`, { type: GET });
const getOrganizationTeamsOfMember = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/member/:${MEMBER_ID}`, {
	type: GET,
});

const acceptInviteToOrganization = new Endpoint(
	API.v1,
	`/organization/:${ORGANIZATION_ID}/member/:${MEMBER_ID}/invite-accept`,
	{ type: POST }
);

const setupInviteToOrganization = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/invite-setup`, {
	type: POST,
});
const cancelSubscription = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/cancel-subscription`, {
	type: POST,
});
const upgradeSubscription = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/plan`, { type: PUT });
const postOrgGallery = new Endpoint(API.v1, `/organization/:${ORGANIZATION_ID}/asset`, { type: POST });

// templates
const getCardTemplates = new Endpoint(API.v1, '/template', { type: GET });
const getCardTemplate = new Endpoint(API.v1, `/template/:${TEMPLATE_ID}`, { type: GET });

// oauth:
const OAuthVerify = new Endpoint(API.v1, '/oauth/login', { type: GET });
const OAuthLogin = new Endpoint(API.v1, '/oauth/login', { type: POST });
const OAuthConsent = new Endpoint(API.v1, '/oauth/consent', { type: GET });
const OAuthConsentUpdate = new Endpoint(API.v1, '/oauth/consent', { type: POST });
const postGoogleOAuth = new Endpoint(API.v2r, `/oauth/google`, { type: POST });
const postGoogleCallback = new Endpoint(API.v2r, `/oauth/google/callback`, { type: POST });

// v2
const webhookSubscribe = new Endpoint(API.v2, `/organization/:${ORGANIZATION_ID}/webhook`, { type: POST });
const getStoryWebhook = new Endpoint(API.v2, `/story/:${STORY_ID}/webhook`, { type: GET });
const deleteWebhook = new Endpoint(API.v2, `/webhook/:${WEBHOOK_ID}`, { type: DELETE });
const sampleWebhook = new Endpoint(API.v2, `/webhook/:${WEBHOOK_ID}/sample`, { type: POST });
const getWebhookLogs = new Endpoint(API.v2, `/webhook/:${WEBHOOK_ID}/logs`, { type: GET });
const postRetryWebhookLog = new Endpoint(API.v2, `/webhook/:${WEBHOOK_ID}/log/:${WEBHOOK_LOG_ID}/retry`, {
	type: POST,
});
const getStoryTemplates = new Endpoint(API.v2, '/story-template', { type: GET });
const getStoryTemplate = new Endpoint(API.v2, `/story-template/:${TEMPLATE_ID}`, { type: GET });
const getFeatureFlags = new Endpoint(API.v2, `/member/:${MEMBER_ID}/flags`, { type: GET });
const postAIStory = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/ai-story`, { type: POST });
const getAIStory = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/ai-story/:${GENERATOR_ID}`, { type: GET });
const patchAIStory = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/ai-story/:${GENERATOR_ID}`, {
	type: PATCH,
});
const getAIUsage = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/ai-usage`, { type: GET });
const postAICard = new Endpoint(API.v2r, `/story/:${STORY_ID}/card/:${CARD_ID}/generate`, { type: POST });
const getAIPrompts = new Endpoint(API.v2r, `/story/:${STORY_ID}/prompt-history`, { type: GET });
const postAIText = new Endpoint(API.v2r, `/story/:${STORY_ID}/generate-text`, { type: POST });
const postAIImage = new Endpoint(API.v2r, `/story/:${STORY_ID}/generate-image`, { type: POST });
const getGoogleIntegrations = new Endpoint(API.v2r, `/integration`, { type: GET });
const deleteGoogleIntegration = new Endpoint(API.v2r, `/integration/:id`, { type: DELETE });
const getIntegrationToken = new Endpoint(API.v2r, `/integration/:id/access-token`, { type: POST });
const getSpreadsheet = new Endpoint(API.v2r, `/integration/:id/spreadsheet/:fileId`, { type: GET });
const getStorySpreadsheetIntegrations = new Endpoint(API.v2r, `/story/:storyId/integration/spreadsheet`, { type: GET });
const postSpreadsheetIntegration = new Endpoint(API.v2r, `/integration/:id/spreadsheet`, { type: POST });
const deleteSpreadsheetIntegration = new Endpoint(API.v2r, `/integration/:id/spreadsheet/:spreadsheetId`, {
	type: DELETE,
});
const putSpreadsheetRange = new Endpoint(API.v2r, '/integration/:id/spreadsheet/:spreadsheetId/range', { type: PUT });
const deleteSpreadsheetRange = new Endpoint(API.v2r, `/integration/:id/spreadsheet/:spreadsheetId/range/:rangeId`, {
	type: DELETE,
});
const getStorycardsDomain = new Endpoint(API.v2r, '/storycards-domain', { type: GET });
const putStorycardsDomain = new Endpoint(API.v2r, `/story/:${STORY_ID}/storycards-domain`, { type: PUT });
const getWidgetViews = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/widget-views`, { type: GET });
const getOrganizationList = new Endpoint(API.v2r, '/organization', { type: GET });
const getOrganization = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}`, { type: GET });
const putOrganizationHomepage = new Endpoint(API.v2r, `/organization/:${ORGANIZATION_ID}/homepage`, { type: PUT });

export {
	ApiType,
	me,
	logout,
	// story:
	getStory,
	createStory,
	duplicateStory,
	deleteStory,
	updateStory,
	updateLatestStory,
	updatePublishedStory,
	unpublishPublishedStory,
	updateStoryUrl,
	postGallery,
	getReportStoryBigPicture,
	storyRollback,
	getReportStoryByCard,
	getCsvUrlStoryByCard,
	getStoryFlowStats,
	setDefaultStoryDomain,
	// member:
	getMember,
	resetPassword,
	restorePassword,
	getMemberPreferences,
	setMemberPreferences,
	updateMember,
	updateMemberAvatar,
	updateMemberEmail,
	updateMemberPassword,
	deleteMember,
	createMember,
	verifyMember,
	verifyMemberResend,
	// team:
	createTeam,
	updateTeam,
	deleteTeam,
	getTeamMembers,
	updateTeamMember,
	deleteTeamMember,
	// organization:
	createOrganization,
	getOrganizationAdminBilling,
	getOrganizationAdminInvoices,
	updateOrganizationBilling,
	updateOrganizationPayment,
	getPubNubAuthKey,
	getOrganizationGoogleFonts,
	getOrganizationTeamsOfMember,
	addMembersToOrganizationTeams,
	removeMemberFromOrganization,
	validateOrganizationName,
	getOrganizationMembers,
	updateOrganizationSettings,
	updateOrganizationMember,
	acceptInviteToOrganization,
	setupInviteToOrganization,
	resendInviteToOrganization,
	cancelSubscription,
	upgradeSubscription,
	postOrgGallery,
	updateOrganizationDomain,
	getOrganizationDomainStatus,
	getOrganizationDomains,
	getOrganizationStories,
	removeOrganizationDomain,
	retryOrganizationDomainConnection,
	setDefaultOrganizationDomain,
	// payment plans
	getPlans,
	getPlanChargeInfo,
	// templates
	getCardTemplates,
	getCardTemplate,
	// analytics:
	getStoryAudience,
	// auth:
	OAuthVerify,
	OAuthLogin,
	OAuthConsent,
	OAuthConsentUpdate,
	postGoogleOAuth,
	postGoogleCallback,
	// v2
	webhookSubscribe,
	getStoryWebhook,
	getWebhookLogs,
	postRetryWebhookLog,
	deleteWebhook,
	sampleWebhook,
	getStoryTemplates,
	getStoryTemplate,
	getFeatureFlags,
	postAIStory,
	getAIStory,
	patchAIStory,
	getAIUsage,
	postAICard,
	getAIPrompts,
	postAIText,
	postAIImage,
	getGoogleIntegrations,
	deleteGoogleIntegration,
	getIntegrationToken,
	getSpreadsheet,
	getStorySpreadsheetIntegrations,
	postSpreadsheetIntegration,
	putSpreadsheetRange,
	deleteSpreadsheetRange,
	deleteSpreadsheetIntegration,
	getStorycardsDomain,
	putStorycardsDomain,
	getWidgetViews,
	auth,
	getOrganizationList,
	getOrganization,
	putOrganizationHomepage,
	cms,
};
